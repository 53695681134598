import React from "react"
import Layout from "../components/layout"

export default function Amenities() {
  return (
    <Layout>
      <section className="page">
        <h2 className="page__heading">Amenities</h2>
        <h3 className="page__heading--section">24/7 Customer Support</h3>
        <p className="page__body">
          Our support staff can be contacted via message, text, phone and
          FaceTime so we can best service your needs.
        </p>
        <h3 className="page__heading--section">Roadside Assistance</h3>
        <p className="page__body">
          Squirrel has an established network of RV mobile repair service technicians who can be on location within hours to make repairs. If the repair cannot be made on site and in a quick manner, Squirrel will send a replacement motorhome.
        </p>
        <h3 className="page__heading--section">Complimentary Gym Membership</h3>
        <p className="page__body">
          A gym membership is included with every Squirrel.
        </p>
        <h3 className="page__heading--section">Amenities</h3>
        <p className="page__body">
          Just like a hotel or your home, Squirrel includes all the comforts of
          home, so you can live, travel and experience life on the road with
          ease and comfort.
        </p>
        <h3 className="page__heading--section">
          Amenities that are included in your Squirrel
        </h3>
        <ul className="page__list">
          <li className="page__list__body">
            200 Watt 12 Volt Monocrystalline Solar Panel, 12V 100W
            High-Efficiency
          </li>
          <li className="page__list__body">
            Fiamma F45 Polar White (8'8") - 6'6" extension retractable awning
          </li>
          <li className="page__list__body">
            <a href="https://www.wingamm.com/us/heated-floor/">Radiant Heat</a>
          </li>
          <li className="page__list__body">
            <a href="https://www.dometic.com/en-us/outdoor/climate-and-comfort/air-conditioners/rv-air-conditioners/dometic-penguin-ii-high-capacity-w-heat-pump-75422">
              Roof mounted Air Conditioning and Heat
            </a>
          </li>
          <li className="page__list__body">
            Wifi Booster 4G LTE Wi-Fi hotspot to connect up to eight wireless
            devices
          </li>
          <li className="page__list__body">
            USB ports and power outlets currently 4 outlets TBD final number of
            outlets and USB ports
          </li>
          <li className="page__list__body">
            Built-In Sheer and blackout shades for all windows
          </li>
        </ul>
        <h3 className="page__heading--section">
          Amenities that are included in your Bedroom
        </h3>
        <ul className="page__list">
          <li className="page__list__body">Squirrel comes with 2 beds.</li>
          <li className="page__list__body">
            Drop down double bed 77.5 x 53 inches with a 771 lbs weight limit
          </li>
          <li className="page__list__body">
            Dining Room Single bed (dinette conversion) 74.5 x 27.5 inches
          </li>
          <li className="page__list__body">1 matttess cover</li>
          <li className="page__list__body">1 ladder</li>
          <li className="page__list__body">2 sets of sheets</li>
          <li className="page__list__body">2 Comforter Blankets</li>
          <li className="page__list__body">4 Pillows + 2 decorative pillow</li>
          <li className="page__list__body">1 Throw Blanket</li>
          <li className="page__list__body">24" TV + remote</li>
          <li className="page__list__body">6 LED Recessed Lights</li>
          <li className="page__list__body">2 Reading Lights at bed</li>
          <li className="page__list__body">Skylight with blackout shade</li>
        </ul>
        ‍
        <h3 className="page__heading--section">
          Amenities that are included in your ‍Dining Room / Living Room
        </h3>
        <ul className="page__list">
          <li className="page__list__body">2 Decorative pillows</li>
          <li className="page__list__body">1 Throw blanket</li>
          <li className="page__list__body">1 Flat screen TV with remote</li>
          <li className="page__list__body">
            J shaped dining lounge booth made from hand stitched Italian
            leather. Seats 6
          </li>
          <li className="page__list__body">
            Swivel driver seat and swivel passenger seat can rotate to face the
            dining table and cabin.
          </li>
          <li className="page__list__body">Upper Closet 47"x33"x18"</li>
          <li className="page__list__body">Lower Closet 25"x25"x18"</li>
          <li className="page__list__body">12 Hangers</li>
          <li className="page__list__body">1 steamer</li>
          <li className="page__list__body">1 cleaning bucket with supplies</li>
          <li className="page__list__body">2 Squirrel robes</li>
          <li className="page__list__body">2 pairs of Squirrel slippers</li>
        </ul>
        <h3 className="page__heading--section">
          Amenities that are included in your Kitchen
        </h3>
        <ul className="page__list">
          <li className="page__list__body">
            Complimentary food starter pack (organic coffee, organic sugar &amp;
            creamer, 2 organic food bars)
          </li>
          <li className="page__list__body">2 Complimentary Squirrel Waters</li>
          <li className="page__list__body">
            <a href="https://www.dometic.com/en-us/outdoor/food-and-beverage/mobile-cooking/rv-and-boat-cooktops/dometic-cooktop-ce-99-20550">
              Dometic CE 99 cooktop 2 burner Stove
            </a>
          </li>
          <li className="page__list__body">Refrigerator / Freezer</li>
          <li className="page__list__body">14"x10" kitchen sink</li>
          <li className="page__list__body">
            6 upper soft closing Italian cabinets
          </li>
          <li className="page__list__body">3 soft closing Italian drawers</li>
          <li className="page__list__body">
            3 soft closing Italian lower cabinets
          </li>
          <li className="page__list__body">
            4 sq feet of countertop space for cooking
          </li>
          <li className="page__list__body">4 Coffee cups / Drinking cups</li>
          <li className="page__list__body">4 plates</li>
          <li className="page__list__body">4 bowls</li>
          <li className="page__list__body">4 wine glasses</li>
          <li className="page__list__body">1 pan</li>
          <li className="page__list__body">1 pot with lid</li>
          <li className="page__list__body">1 cutting board</li>
          <li className="page__list__body">4 forks</li>
          <li className="page__list__body">4 spoons</li>
          <li className="page__list__body">4 knives</li>
          <li className="page__list__body">1 spatula</li>
          <li className="page__list__body">1 ladle</li>
          <li className="page__list__body">1 bottle/wine/can opener</li>
          <li className="page__list__body">1 trash bin w/trash bag</li>
          <li className="page__list__body">1 water boiler</li>
        </ul>
        <h3 className="page__heading--section">
          Amenities that are included in your Bathroom
        </h3>
        <ul className="page__list">
          <li className="page__list__body">
            Bath kit with (toothbrush, toothpaste, shampoo, conditioner, soap,
            moisturizer, sunscreen)
          </li>
          <li className="page__list__body">Shower</li>
          <li className="page__list__body">
            5 Cassette Toilet System with a spare cassette
          </li>
          <li className="page__list__body">
            Sink and Vanity with countertop space
          </li>
          <li className="page__list__body">Medicine cabinet with Mirror</li>
          <li className="page__list__body">Toothbrush holder</li>
          <li className="page__list__body">4 bath towels</li>
          <li className="page__list__body">4 hand towels</li>
          <li className="page__list__body">Roll of toilet paper</li>
          <li className="page__list__body">Toilet tank catalyst</li>
        </ul>
        <h3 className="page__heading--section">Heating and Air Conditioning</h3>
        <ul className="page__list">
          <li className="page__list__body">
            Radiant Heat throughout the interior cabin
          </li>
          <li className="page__list__body">
            Roof mounted Air Conditioning and Heater: Dometic Penguin II
            High-Capacity roof-mounted air conditioning
          </li>
          <li className="page__list__body">
            Air conditioning and Heat at driver and passenger seats
          </li>
        </ul>
        <h3 className="page__heading--section">Toilet and Sewage</h3>
        <ul className="page__list">
          <li className="page__list__body">
            Cassette System
            {/* Cassette System (
            <Link
              to="/videos/oasi-540-plumbing-system"
              state={{
                modal: true,
              }}
              className="nav__button__link"
            >
              watch video
            </Link>
            ) */}
          </li>
          <li className="page__list__body">
            Store extra cassettes in rear storage
          </li>
          <li className="page__list__body">
            Catalyst that converts sewage: Pioneer Products Liquid Super
            Bacteria Activated Enzymes
          </li>
        </ul>
        <h3 className="page__heading--section">Exterior</h3>
        <ul className="page__list">
          <li className="page__list__body">Awning</li>
          <li className="page__list__body">Rear bumper storage</li>
          <li className="page__list__body">Side storage compartment</li>
          <li className="page__list__body">Rear Camorra</li>
        </ul>
      </section>
    </Layout>
  )
}
